<template>
  <div style="background: #eeeeee">
    <div
      style="margin: 150px 0"
    >
      <v-col style="margin-bottom: 100px" class="action-div">
        <Logo />
        <div class="action">
          <h2>
            Contact us
          </h2>
        </div>
      </v-col>
      <v-col class="page-container">
        <div style="max-width: 600px" class="ma-auto">
          <v-form @submit.prevent="">
            <v-text-field
              outlined
              label="Full name"
            />
            <v-text-field
              outlined
              label="Email address"
            />
            <v-textarea
              outlined
              label="Message"
            />
            <v-row justify="end" no-gutters>
              <v-btn
                color="primary"
                type="submit"
              >
                Send
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo';

export default {
  name: "ContactUs",
  components: {
    Logo,
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .v-input {
    &__slot {
      background: #fff !important;
    }
  }
</style>